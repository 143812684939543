<template>
  <v-dialog
    v-if="modal"
    v-model="modal"
    max-width="900"
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>설문 결과</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="px-0">
        <v-container>
          <v-row>
            <v-col style="position: relative" class="px-5">
              <v-card-title class="d-block text-center font-weight-bold px-0">{{
                campaignName
              }}</v-card-title>
              <v-progress-circular
                v-if="resultLoading"
                :indeterminate="resultLoading"
                class="progress_circular"
              ></v-progress-circular>
              <ul class="px-0" style="list-style: none" v-if="resultChart">
                <li v-for="(item, i) in resultItems" :key="i" class="my-5">
                  <h3 class="text-left">Q{{ i + 1 }}</h3>
                  <h3 class="text-left">
                    {{ item.question }} {{ item.response }}
                  </h3>
                  <div class="mt-5">
                    <div
                      :id="'chart' + `${i}`"
                      :style="{
                        height: chartHeightStyle(item.chartType),
                      }"
                      class="mb-7"
                    ></div>
                  </div>
                  <div :id="'genderChart' + `${i}`" style="height: 280px"></div>
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="primary white--text" @click="closeModal">닫기</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "CampaginChart",
  props: {
    modal: Boolean,
    id: Number,
  },
  data() {
    return {
      colorPalette: [
        "#599BFF",
        "#FE83B7",
        "#FFBC57",
        "#9AD74D",
        "#FF876C",
        "#26C7AA",
        "#A675F6",
      ],
      pieChartLabel: {
        show: true,
        formatter: function (data) {
          return data.value + "%";
        },
      },

      resultItems: [],
      campaignName: "",
      resultChart: false,
      resultLoading: false,

      surveyResponse: [],
    };
  },
  mounted() {
    this.getSurveyResponse();
  },
  methods: {
    async getSurveyResponse() {
      this.resultLoading = true;
      let surveyResponse = [];
      let params = {
        campaign_id: this.id,
        per_page: 100,
      };
      await this.axios
        .get(`api/v1/admin/surveys`, { params })
        .then((res) => {
          if (res.data.data.surveys.length > 0) {
            res.data.data.surveys.map((el) => {
              surveyResponse.push({
                response_count: el.response_count,
                response_limit: el.response_limit,
              });
            });
          }

          this.surveyResponse = surveyResponse;
        })
        .catch(() => {
          this.resultLoading = false;
        });

      this.getSurveyResult();
    },

    async getSurveyResult() {
      this.resultItems = [];
      let chartItem = [];
      let chartItems = [];
      await this.axios
        .get(`api/v1/survey/report/${this.id}`)
        .then((res) => {
          if (res.data.stats.answers.length > 0) {
            this.campaignName = res.data.questions.name;
            res.data.stats.answers.map((el) => {
              this.resultChart = true;
              let name = Object.keys(el.answer);
              let value = Object.values(el.answer);
              let chartData = [];
              if (el.chart === "bar") {
                let barData = [
                  {
                    name: name,
                    value: value,
                  },
                ];
                for (let i = 0; barData.length > i; i++) {
                  let keys = barData[i].name;
                  let values = barData[i].value;
                  for (let j = 0; keys.length > j; j++) {
                    if (keys[j] === "") {
                      keys.splice(j, 1);
                      values.splice(j, 1);
                    }
                  }
                }
                chartData = barData;
              } else if (el.chart === "pie") {
                let pieData = name.map((el2, i) => {
                  let data = { name: el2, value: value[i] };
                  return data;
                });
                for (let i = 0; pieData.length > i; i++) {
                  if (pieData[i].name === "") {
                    pieData.splice(i, 1);
                  }
                }
                chartData = pieData;
              }

              chartItem.push({
                chartType: el.chart,
                chartData: chartData,
                genderData: [
                  ["gender", "여자", "남자"],
                  ["10대", el.female_10, el.male_10],
                  ["20대", el.female_20, el.male_20],
                  ["30대", el.female_30, el.male_30],
                  ["40대", el.female_40, el.male_40],
                  ["50대", el.female_50, el.male_50],
                  ["60대", el.female_60, el.male_60],
                  ["70대~", el.female_70, el.male_70],
                ],
              });
            });

            let questions = res.data.questions.surveys.map((el2) => {
              return el2.description;
            });

            let surveyResponse = [];
            this.surveyResponse.map((el, i) => {
              surveyResponse.push({
                response: `(${el.response_count}/${el.response_limit})`,
                question: questions[i],
              });
            });
            this.surveyResponse = surveyResponse;

            this.surveyResponse.map((el, i) => {
              chartItems.push({
                question: el.question,
                response: el.response,
                chartType: chartItem[i]?.chartType,
                chartItem: chartItem[i]?.chartData,
                genderItem: chartItem[i]?.genderData,
              });
            });
            this.resultItems = chartItems;

            this.$nextTick(() => {
              if (this.resultChart) {
                this.getResultChart(this.resultItems);
                this.getGenderChart(this.resultItems);
              }
            });
            this.resultLoading = false;
          }
        })
        .catch(() => {
          this.resultChart = false;
          this.resultLoading = false;
        });
    },

    // 설문 결과에 따른 차트 출력
    getResultChart(items) {
      items.map((el, i) => {
        var chartDom = document.getElementById("chart" + `${i}`);
        var myChart = echarts.init(chartDom);
        var option;

        if (el.chartType === "bar") {
          option = {
            color: this.colorPalette,
            tooltip: {},
            grid: {
              left: "0%",
              top: "5%",
              right: "0%",
              bottom: "15%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: el.chartItem[0].name,
              axisTick: { show: false },
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                data: el.chartItem[0].value,
                type: "bar",
                colorBy: "data",
                barWidth: "15%",
              },
            ],
          };
        } else if (el.chartType === "pie") {
          option = {
            tooltip: {
              trigger: "item",
            },
            legend: {
              bottom: 0,
              left: "center",
            },
            series: [
              {
                type: "pie",
                radius: ["32%", "65%"],
                center: ["50%", "45%"],
                avoidLabelOverlap: false,
                label: this.pieChartLabel,
                emphasis: {
                  scale: false,
                },
                color: this.colorPalette,
                data: el.chartItem,
              },
            ],
          };
        }

        option && myChart.setOption(option);
        window.addEventListener("resize", myChart.resize);
      });
    },

    // 성별에 따른 차트 출력
    getGenderChart(items) {
      items.map((el, i) => {
        var chartDom = document.getElementById("genderChart" + `${i}`);
        var myChart = echarts.init(chartDom);
        var option;

        option = {
          legend: {
            left: "center",
            bottom: 0,
            padding: 0,
          },
          grid: {
            left: "0%",
            top: "5%",
            right: "0%",
            bottom: "15%",
            containLabel: true,
          },
          tooltip: {},
          dataset: {
            source: el.genderItem,
          },
          xAxis: {
            type: "category",
            axisTick: { show: false },
          },
          yAxis: {
            position: "left",
          },
          series: [
            {
              type: "bar",
              color: "#FE83B7",
              barWidth: "15%",
            },
            {
              type: "bar",
              color: "#599BFF",
              barWidth: "15%",
            },
          ],
        };

        option && myChart.setOption(option);
        window.addEventListener("resize", myChart.resize);
      });
    },

    chartHeightStyle(chartType) {
      if (chartType === "bar") {
        return "280px";
      } else if (chartType === "pie") {
        return "360px";
      }
    },

    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.progress_circular {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
