<template>
  <v-container fluid>
    <v-card>
      <v-row no-gutters class="px-5 pt-2 pb-4">
        <v-col cols="12" lg="10" sm="12">
          <v-menu
            v-model="filter"
            :close-on-content-click="false"
            :close-on-click="false"
            :open-on-focus="true"
            transition="scale-transition"
            offset-y
            max-width="400px"
            min-width="400px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-combobox
                v-model="filterList"
                label="검색 필터"
                v-bind="attrs"
                v-on="on"
                multiple
                chips
                hide-details
                readonly
              >
                <template v-slot:selection="data">
                  <v-chip v-if="!data.item.includes('undefined')">
                    <span class="mr-1">{{ data.item }}</span>
                    <v-icon small @click="deleteFilterItem(data.index, 'on')">
                      $delete
                    </v-icon>
                  </v-chip>
                </template>
              </v-combobox>
            </template>
            <div style="padding: 20px; background: white">
              <v-col align="right" class="pa-0">
                <v-btn color="primary" icon @click="filter = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
              <v-text-field
                v-model="dateRangeText"
                label="기간"
                readonly
              ></v-text-field>
              <v-date-picker
                v-model="dates"
                range
                no-title
                locale="ko-KR"
              ></v-date-picker>
              <v-text-field
                v-model="keyword"
                label="설문 토픽 명"
              ></v-text-field>
              <v-combobox
                v-model="category_ids"
                :items="category_items"
                item-text="name"
                item-value="id"
                label="카테고리"
                multiple
                chips
              >
              </v-combobox>
              <v-combobox
                v-model="media_ids"
                :items="media_items"
                item-text="name"
                item-value="id"
                label="노출 언론사"
                multiple
                chips
              >
              </v-combobox>
              <v-combobox
                v-model="client_ids"
                :items="client_items"
                item-text="name"
                item-value="id"
                label="클라이언트"
                multiple
                chips
              >
              </v-combobox>
              <v-combobox
                v-model="survey_keywords"
                label="키워드"
                multiple
                chips
              >
              </v-combobox>
              <v-col align="right" class="pa-0">
                <v-btn
                  color="blue darken-1 white--text mr-2"
                  @click="applyFilterItems('on')"
                  >검색</v-btn
                >
                <v-btn color="primary white--text" @click="filter = false"
                  >닫기</v-btn
                >
              </v-col>
            </div>
          </v-menu>
        </v-col>
        <v-col align="right">
          <v-btn
            color="grey lighten-5"
            @click="refresh('on')"
            class="mt-4 ml-2"
          >
            초기화
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="mt-3">
      <v-col>
        <v-data-table
          :headers="open_headers"
          :items="open_items"
          :loading="on_loading"
          :loading-text="loading_txt"
          hide-default-footer
          :page.sync="page"
          :items-per-page="per_page"
          no-data-text="데이터가 없습니다."
          @click:row="detail($event)"
          style="cursor: pointer"
        >
          <template v-slot:[`item.count_survey`]="{ item }">
            {{ item.count_survey }}개
          </template>
          <template v-slot:[`item.response_count`]="{ item }">
            {{ item.response_count }}{{ "/" + item.response_limit }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <td @click.stop.prevent>
              <v-switch
                inset
                v-model="item.status"
                :true-value="1"
                :false-value="0"
                color="green darken-1"
                @change="changeState(item.id, 'on')"
              ></v-switch>
            </td>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title
                >진행중인 설문 토픽: {{ totalCunt }}건</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                hide-overlay
                transition="dialog-bottom-transition"
                max-width="900px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="primary white--text"
                    rounded
                    style="font-weight: bold"
                    @click="openCampaignModal"
                  >
                    설문 토픽 등록
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-toolbar-title>설문 토픽 {{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-subheader style="font-size: 18px; font-weight: bold">
                    설문 토픽
                  </v-subheader>
                  <v-subheader>설문 토픽 명</v-subheader>
                  <v-container class="pt-0">
                    <v-row no-gutters>
                      <v-text-field
                        v-model="editedItem.name"
                        label="설문 토픽 명"
                        hide-details
                      ></v-text-field>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-subheader>기간</v-subheader>
                  <v-container class="pt-0">
                    <v-row>
                      <v-col cols="12" lg="6">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          min-width="auto"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.start_date"
                              label="시작 날짜"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.start_date"
                            :min="today"
                            :max="editedItem.end_date"
                            @input="menu = false"
                            locale="ko-KR"
                            no-title
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.end_date"
                              label="종료 날짜"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.end_date"
                            :min="editedItem.start_date"
                            @input="menu2 = false"
                            locale="ko-KR"
                            no-title
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-subheader>카테고리</v-subheader>
                  <v-container class="pt-0">
                    <v-row no-gutters>
                      <v-combobox
                        v-model="editedItem.category_ids"
                        :items="category_items"
                        item-text="name"
                        item-value="id"
                        label="카테고리"
                        multiple
                        chips
                        hide-details
                      >
                      </v-combobox>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-subheader>클라이언트</v-subheader>
                  <v-container class="pt-0">
                    <v-row no-gutters>
                      <v-select
                        v-model="editedItem.client_id"
                        :items="client_items"
                        label="클라이언트"
                        item-text="name"
                        item-value="id"
                      ></v-select>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-subheader>노출 언론사</v-subheader>
                  <v-container class="pt-0">
                    <v-row no-gutters>
                      <v-combobox
                        v-model="editedItem.media_ids"
                        :items="media_items"
                        item-text="name"
                        item-value="id"
                        label="노출 언론사"
                        multiple
                        chips
                        hide-details
                      >
                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            @mousedown.prevent
                            @click="
                              editedItem.media_ids.length === media_items.length
                                ? (editedItem.media_ids = [])
                                : (editedItem.media_ids = media_items)
                            "
                          >
                            <v-list-item-action>
                              <v-icon>
                                {{
                                  editedItem.media_ids.length ===
                                  media_items.length
                                    ? "mdi-close-box"
                                    : "mdi-checkbox-blank-outline"
                                }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>전체</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-combobox>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-subheader>단가</v-subheader>
                  <v-container class="pt-0">
                    <v-row>
                      <v-col>
                        <v-text-field
                          @focus="$event.target.select()"
                          reverse
                          prefix="회당"
                          v-model="editedItem.fee_unit"
                          type="number"
                          min="1"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          @focus="$event.target.select()"
                          prefix="원"
                          reverse
                          v-model="editedItem.fee"
                          type="number"
                          min="0"
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-card-actions style="width: 100px; margin: 0 auto">
                        <v-btn
                          :disabled="!campaignValid"
                          color="info darken-1 white--text mb-5"
                          class="mr-2"
                          @click="save()"
                          >{{ formTitle }}
                        </v-btn>
                      </v-card-actions>
                    </v-row>
                  </v-container>
                  <v-divider class="pt-5"></v-divider>
                  <v-btn
                    color="green darken-1 white--text"
                    @click="openSurveyModal(editedItem.id)"
                    style="float: right"
                    class="ma-1 mr-3"
                    rounded
                    :disabled="surveyNumberLimit"
                    >문항 등록
                  </v-btn>
                  <v-subheader style="font-size: 18px; font-weight: bold">
                    문항
                  </v-subheader>
                  <v-container>
                    <v-data-table
                      :headers="survey_headers"
                      :items="survey_items"
                      :loading="survey_loading"
                      :loading-text="loading_txt"
                      :items-per-page="5"
                      :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-minus',
                        nextIcon: 'mdi-plus',
                        'items-per-page-text': '페이지별 표시 될 항목 수',
                      }"
                      no-data-text="데이터가 없습니다."
                    >
                      <template v-slot:[`item.response_count`]="{ item }">
                        {{ item.response_count }}{{ "/" + item.response_limit }}
                      </template>
                      <template v-slot:[`item.status`]="{ item }">
                        <v-switch
                          inset
                          v-model="item.status"
                          :true-value="1"
                          :false-value="0"
                          color="green darken-1"
                          @change="surveyChangeState(item.id)"
                        ></v-switch>
                      </template>
                      <template v-slot:[`item.match_status`]="{ item }">
                        <v-switch
                          inset
                          v-model="item.match_status"
                          :true-value="1"
                          :false-value="0"
                          color="blue darken-1"
                          @change="surveyChangeState(item.id)"
                        ></v-switch>
                      </template>
                      <template v-slot:[`item.surveyActions`]="{ item }">
                        <div style="white-space: nowrap">
                          <v-icon
                            class="mr-1"
                            @click="surveyEditItem(item)"
                            color="green darken-1"
                          >
                            mdi-pencil-box-outline
                          </v-icon>
                          <v-icon
                            class="mr-1"
                            @click="surveyDeleteItem(item)"
                            color="red darken-1"
                          >
                            mdi-delete
                          </v-icon>
                        </div>
                      </template>
                    </v-data-table>
                  </v-container>
                  <v-card-actions class="justify-end">
                    <v-btn color="primary white--text" @click="dialog = false"
                      >닫기
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialog2" max-width="750px">
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-toolbar-title
                      >문항 {{ surveyFormTitle }}</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog2 = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-container>
                    <v-text-field
                      v-model="surveyEditedItem.description"
                      label="내용"
                    ></v-text-field>
                    <v-row no-gutters>
                      <v-combobox
                        label="키워드"
                        multiple
                        v-model="surveyEditedItem.keywords"
                        attach="#"
                      >
                        <template v-slot:selection="data">
                          <v-chip>
                            {{ "#" + data.item }}
                            <v-icon
                              small
                              @click="data.parent.selectItem(data.item)"
                              class="ml-1"
                            >
                              $delete
                            </v-icon>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-row>
                    <v-row no-gutters>
                      <v-combobox
                        label="제외 키워드"
                        multiple
                        v-model="surveyEditedItem.not_keywords"
                        attach="#"
                      >
                        <template v-slot:selection="data">
                          <v-chip color="green darken-3 white--text" label>
                            {{ "#" + data.item }}
                            <v-icon
                              small
                              @click="data.parent.selectItem(data.item)"
                              class="ml-2"
                            >
                              $delete
                            </v-icon>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-row>
                    <v-row no-gutters>
                      <v-select
                        v-model="surveyEditedItem.type"
                        :items="types"
                        label="보기 유형"
                        item-text="name"
                        item-value="name"
                        style="max-width: 300px"
                        @change="typeChange"
                      >
                      </v-select>
                    </v-row>
                    <v-row
                      no-gutters
                      v-show="surveyEditedItem.type === '긍부정 5지선다'"
                    >
                      <v-text-field
                        style="max-width: 200px"
                        class="mr-4"
                        outlined
                        v-model="surveyEditedItem.questions[0]"
                      >
                      </v-text-field>
                      <v-text-field
                        style="max-width: 200px"
                        class="mr-4"
                        outlined
                        v-model="surveyEditedItem.questions[1]"
                      >
                      </v-text-field>
                      <v-text-field
                        style="max-width: 200px"
                        class="mr-4"
                        outlined
                        v-model="surveyEditedItem.questions[2]"
                      >
                      </v-text-field>
                      <v-text-field
                        style="max-width: 200px"
                        class="mr-4"
                        outlined
                        v-model="surveyEditedItem.questions[3]"
                      >
                      </v-text-field>
                      <v-text-field
                        style="max-width: 200px"
                        class="mr-4"
                        outlined
                        v-model="surveyEditedItem.questions[4]"
                      >
                      </v-text-field>
                    </v-row>
                    <v-row
                      no-gutters
                      v-show="surveyEditedItem.type === '긍부정 4지선다'"
                    >
                      <v-text-field
                        style="max-width: 200px"
                        class="mr-4"
                        outlined
                        v-model="surveyEditedItem.questions[0]"
                      >
                      </v-text-field>
                      <v-text-field
                        style="max-width: 200px"
                        class="mr-4"
                        outlined
                        v-model="surveyEditedItem.questions[1]"
                      >
                      </v-text-field>
                      <v-text-field
                        style="max-width: 200px"
                        class="mr-4"
                        outlined
                        v-model="surveyEditedItem.questions[2]"
                      >
                      </v-text-field>
                      <v-text-field
                        style="max-width: 200px"
                        class="mr-4"
                        outlined
                        v-model="surveyEditedItem.questions[3]"
                      >
                      </v-text-field>
                    </v-row>
                    <v-row
                      no-gutters
                      v-show="surveyEditedItem.type === '긍부정 3지선다'"
                    >
                      <v-text-field
                        style="max-width: 200px"
                        class="mr-4"
                        outlined
                        v-model="surveyEditedItem.questions[0]"
                      >
                      </v-text-field>
                      <v-text-field
                        style="max-width: 200px"
                        class="mr-4"
                        outlined
                        v-model="surveyEditedItem.questions[1]"
                      >
                      </v-text-field>
                      <v-text-field
                        style="max-width: 200px"
                        class="mr-4"
                        outlined
                        v-model="surveyEditedItem.questions[2]"
                      >
                      </v-text-field>
                    </v-row>
                    <v-row
                      no-gutters
                      v-show="surveyEditedItem.type === '긍부정 2지선다'"
                    >
                      <v-text-field
                        style="max-width: 200px"
                        class="mr-4"
                        outlined
                        v-model="surveyEditedItem.questions[0]"
                      >
                      </v-text-field>
                      <v-text-field
                        style="max-width: 200px"
                        class="mr-4"
                        outlined
                        v-model="surveyEditedItem.questions[1]"
                      >
                      </v-text-field>
                    </v-row>
                    <v-row
                      no-gutters
                      v-show="surveyEditedItem.type === '지명형'"
                    >
                      <v-text-field
                        style="max-width: 300px"
                        class="mr-4"
                        outlined
                        v-model="surveyEditedItem.questions[0]"
                      >
                      </v-text-field>
                    </v-row>
                    <v-row
                      no-gutters
                      v-show="surveyEditedItem.type === '주관형'"
                    >
                      <v-text-field
                        style="max-width: 300px"
                        class="mr-4"
                        outlined
                        v-model="surveyEditedItem.questions[0]"
                      >
                      </v-text-field>
                    </v-row>
                    <v-row no-gutters>
                      <p style="float: left; margin-bottom: 3px">분석 유형</p>
                    </v-row>
                    <v-row no-gutters>
                      <v-checkbox
                        v-model="surveyEditedItem.analytics_type"
                        value="bar"
                      ></v-checkbox>
                      <v-icon x-large color="green darken-2" class="mr-2">
                        mdi mdi-chart-bar
                      </v-icon>
                      <v-checkbox
                        v-model="surveyEditedItem.analytics_type"
                        value="line"
                      ></v-checkbox>
                      <v-icon x-large color="blue darken-2" class="mr-2">
                        mdi mdi-chart-line
                      </v-icon>
                      <v-checkbox
                        v-model="surveyEditedItem.analytics_type"
                        value="pie"
                      ></v-checkbox>
                      <v-icon x-large color="orange darken-2">
                        mdi mdi-chart-pie
                      </v-icon>
                    </v-row>

                    <v-row no-gutters>
                      <v-text-field
                        v-model="surveyEditedItem.answer_cnt"
                        style="max-width: 300px"
                        suffix="개"
                        type="number"
                        label="다중 답변"
                        min="1"
                        max="10"
                        hide-details
                      >
                      </v-text-field>
                    </v-row>

                    <v-row no-gutters>
                      <v-text-field
                        v-model="surveyEditedItem.response_limit"
                        style="max-width: 300px"
                        suffix="회"
                        type="number"
                        label="응답 수 설정"
                        min="0"
                        hide-details
                      >
                      </v-text-field>
                    </v-row>
                  </v-container>
                  <v-card-actions class="justify-end">
                    <v-btn
                      :disabled="!surveyValid"
                      color="blue darken-1 white--text"
                      @click="surveySave()"
                      >{{ surveyFormTitle }}
                    </v-btn>
                    <v-btn color="primary white--text" @click="dialog2 = false"
                      >닫기
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.result`]="{ item }">
            <td @click.stop.prevent>
              <v-chip
                @click="showSurveyResult(item.id)"
                color="primary darken-1 white--text"
                :disabled="item.count_survey === 0"
              >
                확인
              </v-chip>
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td @click.stop.prevent>
              <v-icon
                class="mr-1"
                @click="editItem(item)"
                color="green darken-1"
              >
                mdi-pencil-box-outline
              </v-icon>
            </td>
          </template>
        </v-data-table>
        <v-pagination
          v-model="page"
          :length="pageCount"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          color="primary"
          :total-visible="7"
          class="float-right mt-4 mb-5"
        ></v-pagination>
      </v-col>
    </v-row>

<!-- start  WORKING  활성 대기 중  -->
    <v-card>
      <v-row no-gutters class="px-5 pt-2 pb-4">
        <v-col cols="12" lg="10" sm="12">
          <v-menu
            v-model="hold_filter"
            :close-on-content-click="false"
            :close-on-click="false"
            :open-on-focus="true"
            transition="scale-transition"
            offset-y
            max-width="400px"
            min-width="400px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-combobox
                v-model="hold_filterList"
                label="검색 필터"
                v-bind="attrs"
                v-on="on"
                multiple
                chips
                hide-details
                readonly
              >
                <template v-slot:selection="data">
                  <v-chip v-if="!data.item.includes('undefined')">
                    <span class="mr-1">{{ data.item }}</span>
                    <v-icon small @click="deleteFilterItem(data.index, 'hold')">
                      $delete
                    </v-icon>
                  </v-chip>
                </template>
              </v-combobox>
            </template>
            <div style="padding: 20px; background: white">
              <v-col align="right" class="pa-0">
                <v-btn color="primary" icon @click="hold_filter = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
              <v-text-field
                v-model="holdDateRangeText"
                label="기간"
                readonly
              ></v-text-field>
              <v-date-picker
                v-model="hold_dates"
                range
                no-title
                locale="ko-KR"
              ></v-date-picker>
              <v-text-field
                v-model="hold_keyword"
                label="설문 토픽 명"
              ></v-text-field>
              <v-combobox
                v-model="hold_category_ids"
                :items="category_items"
                item-text="name"
                item-value="id"
                label="카테고리"
                multiple
                chips
              >
              </v-combobox>
              <v-combobox
                v-model="hold_media_ids"
                :items="media_items"
                item-text="name"
                item-value="id"
                label="노출 언론사"
                multiple
                chips
              >
              </v-combobox>
              <v-combobox
                v-model="hold_client_ids"
                :items="client_items"
                item-text="name"
                item-value="id"
                label="클라이언트"
                multiple
                chips
              >
              </v-combobox>
              <v-combobox
                v-model="hold_survey_keywords"
                label="키워드"
                multiple
                chips
              >
              </v-combobox>
              <v-col align="right" class="pa-0">
                <v-btn
                  color="blue darken-1 white--text mr-2"
                  @click="applyFilterItems('hold')"
                  >검색</v-btn
                >
                <v-btn color="primary white--text" @click="hold_filter = false"
                  >닫기</v-btn
                >
              </v-col>
            </div>
          </v-menu>
        </v-col>
        <v-col align="right">
          <v-btn
            color="grey lighten-5"
            @click="refresh('hold')"
            class="mt-4 ml-2"
          >
            초기화
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="mt-2">
      <v-col>
        <v-data-table
          :headers="hold_headers"
          :items="hold_items"
          :loading="hold_loading"
          :loading-text="loading_txt"
          hide-default-footer
          :page.sync="hold_page"
          :items-per-page="hold_per_page"
          class="pb-1"
          no-data-text="데이터가 없습니다."
          @click:row="detail($event)"
          style="cursor: pointer"
        >
          <template v-slot:[`item.count_survey`]="{ item }">
            {{ item.count_survey }}개
          </template>
          <template v-slot:[`item.response_count`]="{ item }">
            {{ item.response_count }}{{ "/" + item.response_limit }}
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title
                >광고주 등록 설문 토픽: {{ holdTotalCount }}건</v-toolbar-title
              >
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <td @click.stop.prevent>
              <v-btn
                outlined
                color="green darken-1"
                size="small"
                @click="changeState(item.id, 'holdToOn')"
                title="activate"
              >on</v-btn>
              <v-btn
                  outlined
                  color="red darken-1"
                  size="small"
                  @click="changeState(item.id, 'holdToOff')"
                  title="terminate"
              >off</v-btn>
            </td>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <td @click.stop.prevent>
              <v-icon
                  class="mr-1"
                  @click="editHoldItem(item)"
                  color="green darken-1"
              >
                mdi-pencil-box-outline
              </v-icon>
            </td>
          </template>
        </v-data-table>
        <v-pagination
          v-model="hold_page"
          :length="holdPageCount"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          color="primary"
          class="float-right mt-4"
          :total-visible="7"
        ></v-pagination>
      </v-col>
    </v-row>

    <!--    WORKING  활성 대기 중 // end -->

    <v-card>
      <v-row no-gutters class="px-5 pt-2 pb-4">
        <v-col cols="12" lg="10" sm="12">
          <v-menu
              v-model="filter2"
              :close-on-content-click="false"
              :close-on-click="false"
              :open-on-focus="true"
              transition="scale-transition"
              offset-y
              max-width="400px"
              min-width="400px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-combobox
                  v-model="filterList2"
                  label="검색 필터"
                  v-bind="attrs"
                  v-on="on"
                  multiple
                  chips
                  hide-details
                  readonly
              >
                <template v-slot:selection="data">
                  <v-chip v-if="!data.item.includes('undefined')">
                    <span class="mr-1">{{ data.item }}</span>
                    <v-icon small @click="deleteFilterItem(data.index, 'off')">
                      $delete
                    </v-icon>
                  </v-chip>
                </template>
              </v-combobox>
            </template>
            <div style="padding: 20px; background: white">
              <v-col align="right" class="pa-0">
                <v-btn color="primary" icon @click="filter2 = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
              <v-text-field
                  v-model="dateRangeText2"
                  label="기간"
                  readonly
              ></v-text-field>
              <v-date-picker
                  v-model="dates2"
                  range
                  no-title
                  locale="ko-KR"
              ></v-date-picker>
              <v-text-field
                  v-model="keyword2"
                  label="설문 토픽 명"
              ></v-text-field>
              <v-combobox
                  v-model="category_ids2"
                  :items="category_items"
                  item-text="name"
                  item-value="id"
                  label="카테고리"
                  multiple
                  chips
              >
              </v-combobox>
              <v-combobox
                  v-model="media_ids2"
                  :items="media_items"
                  item-text="name"
                  item-value="id"
                  label="노출 언론사"
                  multiple
                  chips
              >
              </v-combobox>
              <v-combobox
                  v-model="client_ids2"
                  :items="client_items"
                  item-text="name"
                  item-value="id"
                  label="클라이언트"
                  multiple
                  chips
              >
              </v-combobox>
              <v-combobox
                  v-model="survey_keywords2"
                  label="키워드"
                  multiple
                  chips
              >
              </v-combobox>
              <v-col align="right" class="pa-0">
                <v-btn
                    color="blue darken-1 white--text mr-2"
                    @click="applyFilterItems('off')"
                >검색</v-btn
                >
                <v-btn color="primary white--text" @click="filter2 = false"
                >닫기</v-btn
                >
              </v-col>
            </div>
          </v-menu>
        </v-col>
        <v-col align="right">
          <v-btn
              color="grey lighten-5"
              @click="refresh('off')"
              class="mt-4 ml-2"
          >
            초기화
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="mt-2">
      <v-col>
        <v-data-table
            :headers="close_headers"
            :items="close_items"
            :loading="off_loading"
            :loading-text="loading_txt"
            hide-default-footer
            :page.sync="off_page"
            :items-per-page="off_per_page"
            class="pb-1"
            no-data-text="데이터가 없습니다."
            @click:row="detail($event)"
            style="cursor: pointer"
        >
          <template v-slot:[`item.count_survey`]="{ item }">
            {{ item.count_survey }}개
          </template>
          <template v-slot:[`item.response_count`]="{ item }">
            {{ item.response_count }}{{ "/" + item.response_limit }}
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title
              >종료된 설문 토픽: {{ offTotalCount }}건</v-toolbar-title
              >
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <td @click.stop.prevent>
              <v-switch
                  inset
                  v-model="item.status"
                  :true-value="1"
                  :false-value="0"
                  color="green darken-1"
                  @change="changeState(item.id, 'off')"
              ></v-switch>
            </td>
          </template>
          <template v-slot:[`item.result`]="{ item }">
            <td @click.stop.prevent>
              <v-chip
                  @click="showSurveyResult(item.id)"
                  color="primary darken-1 white--text"
                  :disabled="item.count_survey === 0"
              >
                확인
              </v-chip>
            </td>
          </template>
          <template v-slot:[`item.update`]="{ item }">
            <td @click.stop.prevent>
              <v-chip @click="reSave(item)" color="orange darken-1 white--text">
                재등록
              </v-chip>
            </td>
          </template>
        </v-data-table>
        <v-pagination
            v-model="off_page"
            :length="offPageCount"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            color="primary"
            class="float-right mt-4"
            :total-visible="7"
        ></v-pagination>
      </v-col>
    </v-row>

    <CampaginChart
      v-if="modal"
      :modal="modal"
      :id="id"
      @closeModal="closeModal"
    />
  </v-container>
</template>

<script>
import moment from "moment";
import CampaginChart from "@/components/modal/CampaginChart.vue";

export default {
  name: "CampaignManage",
  components: {
    CampaginChart,
  },
  data() {
    return {
      today: moment().format("YYYY-MM-DD"),
      filter: false,
      dates: [],
      category_ids: [],
      media_ids: [],
      client_ids: [],
      survey_keywords: [],
      keyword: "",
      start_date: "",
      end_date: "",
      filterList: [],

      hold_filter: false,
      hold_dates: [],
      hold_category_ids: [],
      hold_media_ids: [],
      hold_client_ids: [],
      hold_survey_keywords: [],
      hold_keyword: "",
      hold_start_date: "",
      hold_end_date: "",
      hold_filterList: [],

      filter2: false,
      dates2: [],
      category_ids2: [],
      media_ids2: [],
      client_ids2: [],
      survey_keywords2: [],
      keyword2: "",
      start_date2: "",
      end_date2: "",
      filterList2: [],

      open_headers: [
        {
          text: "설문 토픽 명",
          value: "name",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "클라이언트",
          value: "client_name",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "기간",
          value: "date",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "문항",
          value: "count_survey",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "응답/제한",
          value: "response_count",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "카테고리",
          value: "category_name",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "언론사",
          value: "media_name",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "ON/OFF",
          value: "status",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "설문 결과",
          value: "result",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },
        {
          text: "수정",
          value: "actions",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },
      ],
      hold_headers: [
        {
          text: "설문 토픽 명",
          value: "name",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "클라이언트",
          value: "client_name",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "기간",
          value: "date",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "문항",
          value: "count_survey",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "응답/제한",
          value: "response_count",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "카테고리",
          value: "category_name",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "언론사",
          value: "media_name",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "ON/OFF",
          value: "status",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },{
          text: "자세히",
          value: "actions",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },
      ],
      close_headers: [
        {
          text: "설문 토픽 명",
          value: "name",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "클라이언트",
          value: "client_name",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "기간",
          value: "date",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "문항",
          value: "count_survey",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "응답/제한",
          value: "response_count",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "카테고리",
          value: "category_name",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "언론사",
          value: "media_name",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "ON/OFF",
          value: "status",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "설문 결과",
          value: "result",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },
        {
          text: "",
          value: "update",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
      ],
      survey_headers: [
        {
          text: "NO",
          value: "no",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "내용",
          value: "description",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "보기 유형",
          value: "type",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "분석 유형",
          value: "analytics_type",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "응답/제한",
          value: "response_count",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "ON/OFF",
          value: "status",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "키워드 매칭",
          value: "match_status",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "수정/삭제",
          value: "surveyActions",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
      ],
      open_items: [],
      hold_items: [],
      close_items: [],
      survey_items: [],
      dialog: false,
      dialog2: false,
      dialog3: false,
      on_loading: false,
      hold_loading: false,
      off_loading: false,
      survey_loading: false,
      loading_txt: "로딩중.. 기다려주세요.",
      dialogDelete: false,
      surveyDialogDelete: false,
      editedItem: {
        name: null,
        media_ids: [],
        category_ids: [],
        surveys: [],
        client_id: null,
        start_date: null,
        end_date: null,
        fee_unit: 1,
        fee: 0,
      },
      defaultItem: {
        name: null,
        media_ids: [],
        category_ids: [],
        surveys: [],
        client_id: null,
        start_date: null,
        end_date: null,
        fee_unit: 1,
        fee: 0,
      },
      surveyEditedItem: {
        campaign_id: null,
        description: null,
        type: null,
        keywords: null,
        not_keywords: null,
        analytics_type: null,
        response_count: 0,
        response_limit: 0,
        answer_cnt: 1,
        status: null,
        match_status: null,
        questions: [],
      },
      surveyDefaultItem: {
        campaign_id: null,
        description: null,
        type: null,
        keywords: null,
        not_keywords: null,
        analytics_type: null,
        response_count: 0,
        response_limit: 0,
        answer_cnt: 1,
        status: 1,
        match_status: 0,
        questions: [],
      },
      category_items: [],
      media_items: [],
      client_items: [],
      menu: false,
      menu2: false,
      select: null,
      types: [
        // { id: 7, name: "긍부정 6지선다" },
        { id: 1, name: "긍부정 5지선다" },
        { id: 6, name: "긍부정 4지선다" },
        { id: 2, name: "긍부정 3지선다" },
        { id: 3, name: "긍부정 2지선다" },
        { id: 4, name: "지명형" },
        { id: 5, name: "주관형" },
      ],
      editedIndex: -1,
      surveyEditedIndex: -1,
      isActive: true,

      page: 1,
      per_page: 7,
      pageCount: 1,

      off_page: 1,
      off_per_page: 7,
      offPageCount: 1,

      hold_page: 1,
      hold_per_page: 7,
      holdPageCount: 1,

      media_id: parseInt(localStorage.getItem("media_id")),
      is_reSave: false,
      // 필터 목록에 표시할 키 값
      defaultFilterKeys: [
        "activeStatus",
        "createDateRanges",
        "campaignCategories",
        "campaignMedias",
      ],
      // 선택된 필터 키와 값
      selectedFilterValue: {
        activeStatus: [1],
        // createDateRanges: [this.startDt, this.endDt],
      },
      holdDefaultFilterKeys: [
        "holdActiveStatus",
        "holdCreateDateRanges",
        "holdCampaignCategories",
        "holdCampaignMedias",
      ],
      // 선택된 필터 키와 값
      holdSelectedFilterValue: {
        holdActiveStatus: [2],
        // createDateRanges: ["2022-10-11", "2022-10-25"],
      },
      // 필터 목록에 표시할 키 값
      offDefaultFilterKeys: [
        "offActiveStatus",
        "offCreateDateRanges",
        "offCampaignCategories",
        "offCampaignMedias",
      ],
      // 선택된 필터 키와 값
      offSelectedFilterValue: {
        offActiveStatus: [0],
        // createDateRanges: ["2022-10-11", "2022-10-25"],
      },

      startDt: "",
      endDt: "",
      state: 1,
      categoryIds: "",
      mediaIds: "",

      holdStartDt: "",
      holdEndDt: "",
      holdState: 0,
      holdCategoryIds: "",
      holdMediaIds: "",

      offStartDt: "",
      offEndDt: "",
      offState: 0,
      offCategoryIds: "",
      offMediaIds: "",

      campaignId: null,
      search: "",
      offSearch: "",

      totalCunt: 0,
      offTotalCount: 0,
      holdTotalCount: 0,

      modal: false,
      id: "",

      surveyLength: 0,
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    dateRangeText2() {
      return this.dates2.join(" ~ ");
    },
    holdDateRangeText() {
      return this.hold_dates.join(" ~ ");
    },
    formTitle() {
      return this.editedIndex === -1 ? "등록" : "수정";
    },
    surveyFormTitle() {
      return this.surveyEditedIndex === -1 ? "등록" : "수정";
    },
    campaignValid() {
      if (
        this.editedItem.name &&
        this.editedItem.start_date &&
        this.editedItem.end_date &&
        // this.editedItem.category_ids.length > 0 &&
        this.editedItem.client_id
      ) {
        return true;
      } else {
        return false;
      }
    },
    // 나중에 더 보완
    surveyValid() {
      if (
        this.surveyEditedItem.description &&
        this.surveyEditedItem.type &&
        this.surveyEditedItem.questions.length > 0 &&
        this.surveyEditedItem.analytics_type &&
        this.surveyEditedItem.response_limit
      ) {
        return true;
      } else {
        return false;
      }
    },
    surveyNumberLimit() {
      if (!this.isActive && this.surveyLength > 4) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    // editedItem(val) {
    //   console.log(val);
    // },
    filter() {
      this.filter2 = false;
    },
    filter2() {
      this.filter = false;
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    surveyDialogDelete(val) {
      val || this.surveyCloseDelete();
    },
    page() {
      this.$store.commit("SET_PAGE", this.page);
      this.getData();
    },

    hold_page() {
      this.$store.commit("SET_HOLD_PAGE", this.hold_page);
      this.getHoldData();
    },

    off_page() {
      this.$store.commit("SET_OFF_PAGE", this.off_page);
      this.getOffData();
    },
  },
  mounted() {
    this.getData();
    this.getHoldData();
    this.getOffData();
    this.getCategory();
    this.getMedia();
    this.getClient();
  },
  methods: {
    applyFilterItems(state) {
      if (state === "on") {
        this.start_date = this.dates[0] ? this.dates[0] : "";
        this.end_date = this.dates[1] ? this.dates[1] : "";

        let date = `기간: ${
          this.dateRangeText ? this.dateRangeText : undefined
        }`;

        let keyword = `설문 토픽 명: ${
          this.keyword ? this.keyword : undefined
        }`;

        let category = this.category_ids.map((item) => item.name);
        if (category.length > 1) {
          category = `카테고리: ${category[0]} 외 ${category.length - 1}개`;
        } else {
          category = `카테고리: ${category[0]}`;
        }

        let media = this.media_ids.map((item) => item.name);
        if (media.length > 1) {
          media = `언론사: ${media[0]} 외 ${media.length - 1}개`;
        } else {
          media = `언론사: ${media[0]}`;
        }

        let client = this.client_ids.map((item) => item.name);
        if (client.length > 1) {
          client = `클라이언트: ${client[0]} 외 ${client.length - 1}개`;
        } else {
          client = `클라이언트: ${client[0]}`;
        }

        let survey_keyword = "";
        if (this.survey_keywords.length > 1) {
          survey_keyword = `키워드: ${this.survey_keywords[0]} 외 ${
            this.survey_keywords.length - 1
          }개`;
        } else {
          survey_keyword = `키워드: ${this.survey_keywords[0]}`;
        }

        this.filterList = [
          date,
          keyword,
          category,
          media,
          client,
          survey_keyword,
        ];
        this.filter = false;

        this.getData();
      } else if (state === "hold") {
        this.hold_start_date = this.hold_dates[0] ? this.hold_dates[0] : "";
        this.hold_end_date = this.hold_dates[1] ? this.hold_dates[1] : "";

        let date = `기간: ${
            this.holdDateRangeText ? this.holdDateRangeText : undefined
        }`;

        let keyword = `설문 토픽 명: ${
            this.hold_keyword ? this.hold_keyword : undefined
        }`;

        let category = this.hold_category_ids.map((item) => item.name);
        if (category.length > 1) {
          category = `카테고리: ${category[0]} 외 ${category.length - 1}개`;
        } else {
          category = `카테고리: ${category[0]}`;
        }

        let media = this.hold_media_ids.map((item) => item.name);
        if (media.length > 1) {
          media = `언론사: ${media[0]} 외 ${media.length - 1}개`;
        } else {
          media = `언론사: ${media[0]}`;
        }

        let client = this.hold_client_ids.map((item) => item.name);
        if (client.length > 1) {
          client = `클라이언트: ${client[0]} 외 ${client.length - 1}개`;
        } else {
          client = `클라이언트: ${client[0]}`;
        }

        let survey_keyword = "";
        if (this.hold_survey_keywords.length > 1) {
          survey_keyword = `키워드: ${this.hold_survey_keywords[0]} 외 ${
              this.hold_survey_keywords.length - 1
          }개`;
        } else {
          survey_keyword = `키워드: ${this.hold_survey_keywords[0]}`;
        }

        this.hold_filterList = [
          date,
          keyword,
          category,
          media,
          client,
          survey_keyword,
        ];
        this.hold_filter = false;

        this.getHoldData();
      } else if (state === "off") {
        this.start_date2 = this.dates2[0] ? this.dates2[0] : "";
        this.end_date2 = this.dates2[1] ? this.dates2[1] : "";

        let date = `기간: ${
          this.dateRangeText2 ? this.dateRangeText2 : undefined
        }`;

        let keyword = `설문 토픽 명: ${
          this.keyword2 ? this.keyword2 : undefined
        }`;

        let category = this.category_ids2.map((item) => item.name);
        if (category.length > 1) {
          category = `카테고리: ${category[0]} 외 ${category.length - 1}개`;
        } else {
          category = `카테고리: ${category[0]}`;
        }

        let media = this.media_ids2.map((item) => item.name);
        if (media.length > 1) {
          media = `언론사: ${media[0]} 외 ${media.length - 1}개`;
        } else {
          media = `언론사: ${media[0]}`;
        }

        let client = this.client_ids2.map((item) => item.name);
        if (client.length > 1) {
          client = `클라이언트: ${client[0]} 외 ${client.length - 1}개`;
        } else {
          client = `클라이언트: ${client[0]}`;
        }

        let survey_keyword = "";
        if (this.survey_keywords2.length > 1) {
          survey_keyword = `키워드: ${this.survey_keywords2[0]} 외 ${
            this.survey_keywords2.length - 1
          }개`;
        } else {
          survey_keyword = `키워드: ${this.survey_keywords2[0]}`;
        }

        this.filterList2 = [
          date,
          keyword,
          category,
          media,
          client,
          survey_keyword,
        ];
        this.filter2 = false;

        this.getOffData();
      }
    },
    refresh(state) {
      if (state === "on") {
        this.keyword = "";
        this.dates = [];
        this.start_date = "";
        this.end_date = "";
        this.category_ids = [];
        this.media_ids = [];
        this.client_ids = [];
        this.survey_keywords = [];
        this.filterList = [];
        this.getData();
      } else if (state === "hold") {
        this.hold_keyword = "";
        this.hold_dates = [];
        this.hold_start_date = "";
        this.hold_end_date = "";
        this.hold_category_ids = [];
        this.hold_media_ids = [];
        this.hold_client_ids = [];
        this.hold_survey_keywords = [];
        this.hold_filterList = [];
        this.getOffData();
      } else if (state === "off") {
        this.keyword2 = "";
        this.dates2 = [];
        this.start_date2 = "";
        this.end_date2 = "";
        this.category_ids2 = [];
        this.media_ids2 = [];
        this.client_ids2 = [];
        this.survey_keywords2 = [];
        this.filterList2 = [];
        this.getOffData();
      }
    },
    deleteFilterItem(i, state) {
      if (state === "on") {
        this.filter = false;
        this.filterList[i] = "undefined";
        if (i === 0) {
          this.dates = [];
          this.start_date = "";
          this.end_date = "";
        } else if (i === 1) {
          this.keyword = "";
        } else if (i === 2) {
          this.category_ids = [];
        } else if (i === 3) {
          this.media_ids = [];
        } else if (i === 4) {
          this.client_ids = [];
        } else if (i === 5) {
          this.survey_keywords = [];
        }
        this.getData();
      } else if (state === "hold") {
        this.hold_filter = false;
        this.hold_filterList[i] = "undefined";
        if (i === 0) {
          this.hold_dates = [];
          this.hold_start_date = "";
          this.hold_end_date = "";
        } else if (i === 1) {
          this.hold_keyword = "";
        } else if (i === 2) {
          this.hold_category_ids = [];
        } else if (i === 3) {
          this.hold_media_ids = [];
        } else if (i === 4) {
          this.hold_client_ids = [];
        } else if (i === 5) {
          this.hold_survey_keywords = [];
        }
        this.getHoldData();
      } else if (state === "off") {
        this.filter2 = false;
        this.filterList2[i] = "undefined";
        if (i === 0) {
          this.dates2 = [];
          this.start_date2 = "";
          this.end_date2 = "";
        } else if (i === 1) {
          this.keyword2 = "";
        } else if (i === 2) {
          this.category_ids2 = [];
        } else if (i === 3) {
          this.media_ids2 = [];
        } else if (i === 4) {
          this.client_ids2 = [];
        } else if (i === 5) {
          this.survey_keywords2 = [];
        }
        this.getOffData();
      }
    },
    getClient() {
      let clients = [];
      this.axios.get("api/v1/admin/clients").then((res) => {
        if (res.data.data.clients.length > 0) {
          res.data.data.clients.map((item) => {
            clients.push({
              id: item.id,
              name: item.name,
            });
          });
        }
        this.client_items = clients;
      });
    },

    closeModal() {
      this.modal = false;
    },

    showSurveyResult(id) {
      this.modal = true;
      this.id = id;
    },

    applyFilters(filters) {
      this.selectedFilterValue = filters ? { ...filters } : {};
      this.startDt = filters.createDateRanges
        ? filters.createDateRanges[0]
        : "";
      this.endDt = filters.createDateRanges ? filters.createDateRanges[1] : "";
      this.categoryIds = filters.campaignCategories
        ? filters.campaignCategories
        : "";
      this.mediaIds = filters.campaignMedias ? filters.campaignMedias : [];
      // 해당 필터로 설문 토픽 조회 API 요청
      this.getData();
    },
    offApplyFilters(filters) {
      this.offSelectedFilterValue = filters ? { ...filters } : {};
      this.offStartDt = filters.offCreateDateRanges
        ? filters.offCreateDateRanges[0]
        : "";
      this.offEndDt = filters.offCreateDateRanges
        ? filters.offCreateDateRanges[1]
        : "";
      this.offCategoryIds = filters.offCampaignCategories
        ? filters.offCampaignCategories
        : [];
      this.offMediaIds = filters.offCampaignMedias
        ? filters.offCampaignMedias
        : [];
      // 해당 필터로 설문 토픽 조회 API 요청
      this.getOffData();
    },
    getData() {
      this.on_loading = true;
      let on_items = [];
      let resCount = [];
      let resLimit = [];
      let on_start = "";
      let on_end = "";

      let categoryIds = [];
      let mediaIds = [];
      let clientIds = [];
      let url = `api/v1/admin/campaigns`;
      let params = {
        is_active: 1,
        page: this.page,
        per_page: this.per_page,
        start_date: this.start_date,
        end_date: this.end_date,
        name: this.keyword,
      };
      if (this.category_ids.length > 0) {
        categoryIds = this.category_ids.map((item) => item.id);
        params["category_ids"] = categoryIds;
      }
      if (this.media_ids.length > 0) {
        mediaIds = this.media_ids.map((item) => item.id);
        params["media_ids"] = mediaIds;
      }
      if (this.client_ids.length > 0) {
        clientIds = this.client_ids.map((item) => item.id);
        params["client_ids"] = clientIds;
      }
      if (this.survey_keywords.length > 0) {
        params["survey_keywords"] = this.survey_keywords;
      }
      this.axios
        .get(url, {
          params,
        })
        .then((res) => {
          if (res.data.data.campaigns.length > 0) {
            res.data.data.campaigns.map((item) => {
              on_start = item.start_date ? item.start_date.substr(0, 10) : null;
              on_end = item.end_date ? item.end_date.substr(0, 10) : null;
              resCount = item.surveys.map((item) => item.response_count);
              resLimit = item.surveys.map((item) => item.response_limit);
              const result = resCount.reduce(function add(sum, currValue) {
                return sum + currValue;
              }, 0);
              const result2 = resLimit.reduce(function add(sum, currValue) {
                return sum + currValue;
              }, 0);
              on_items.push({
                id: item.id,
                name: item.name,
                date:
                  item.start_date || item.end_date
                    ? on_start + "~" + on_end
                    : null,
                start_date: item.start_date,
                end_date: item.end_date,
                count_survey: item.surveys_count,
                category_ids: item.categories.map((item) => item.id),
                category_name: item.categories.map((item) => item.name),
                media_name: item.medias.map((item) => item.name),
                media_ids: item.medias.map((item) => item.id),
                client_name: item.client.name,
                client_id: item.client_id,
                status: item.status,
                response_count: result,
                response_limit: result2,
                fee_unit: item.fee[0]?.unit,
                fee: item.fee[0]?.fee,
              });
            });
          }
          this.open_items = on_items;
          this.totalCunt = res.data.data.totalCount;
          this.on_loading = false;
          if (
            Number.isInteger(res.data.data.totalCount / this.per_page) == false
          ) {
            this.pageCount =
              Math.floor(res.data.data.totalCount / this.per_page) + 1;
          } else {
            this.pageCount = res.data.data.totalCount / this.per_page;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getHoldData() {
      this.hold_loading = true;
      let hold_items = [];
      let resCount = [];
      let resLimit = [];
      let hold_start = "";
      let hold_end = "";

      let categoryIds = [];
      let mediaIds = [];
      let clientIds = [];
      let url = `api/v1/admin/campaigns`;
      let params = {
        is_active: 2,
        page: this.hold_page,
        per_page: this.hold_per_page,
        start_date: this.hold_start_date,
        end_date: this.hold_end_date,
        name: this.hold_keyword,
      };
      if (this.hold_category_ids.length > 0) {
        categoryIds = this.hold_category_ids.map((item) => item.id);
        params["category_ids"] = categoryIds;
      }
      if (this.hold_media_ids.length > 0) {
        mediaIds = this.hold_media_ids.map((item) => item.id);
        params["media_ids"] = mediaIds;
      }
      if (this.hold_client_ids.length > 0) {
        clientIds = this.hold_client_ids.map((item) => item.id);
        params["client_ids"] = clientIds;
      }
      if (this.hold_survey_keywords.length > 0) {
        params["survey_keywords"] = this.hold_survey_keywords;
      }
      this.axios
          .get(url, { params })
          .then((res) => {
            if (res.data.data.campaigns.length > 0) {
              res.data.data.campaigns.map((item) => {
                hold_start = item.start_date
                    ? item.start_date.substr(0, 10)
                    : null;
                hold_end = item.end_date ? item.end_date.substr(0, 10) : null;
                resCount = item.surveys.map((item) => item.response_count);
                resLimit = item.surveys.map((item) => item.response_limit);
                const result = resCount.reduce(function add(sum, currValue) {
                  return sum + currValue;
                }, 0);
                const result2 = resLimit.reduce(function add(sum, currValue) {
                  return sum + currValue;
                }, 0);
                hold_items.push({
                  id: item.id,
                  name: item.name,
                  date:
                      item.start_date || item.end_date
                          ? hold_start + "~" + hold_end
                          : null,
                  start_date: item.start_date,
                  end_date: item.end_date,
                  surveys: item.surveys,
                  count_survey: item.surveys_count,
                  category_ids: item.categories.map((item) => item.id),
                  category_name: item.categories.map((item) => item.name),
                  media_ids: item.medias.map((item) => item.id),
                  media_name: item.medias.map((item) => item.name),
                  client_name: item.client.name,
                  client_id: item.client_id,
                  status: item.status,
                  response_count: result,
                  response_limit: result2,
                });
              });
            }
            this.hold_items = hold_items;
            this.holdTotalCount = res.data.data.totalCount;
            this.hold_loading = false;
            if (
                Number.isInteger(res.data.data.totalCount / this.hold_per_page) === false
            ) {
              this.holdPageCount =
                  Math.floor(res.data.data.totalCount / this.hold_per_page) + 1;
            } else {
              this.holdPageCount = res.data.data.totalCount / this.hold_per_page;
            }
          })
          .catch((err) => {
            console.error(err);
          });
    },
    getOffData() {
      this.off_loading = true;
      let off_items = [];
      let resCount = [];
      let resLimit = [];
      let off_start = "";
      let off_end = "";

      let categoryIds = [];
      let mediaIds = [];
      let clientIds = [];
      let url = `api/v1/admin/campaigns`;
      let params = {
        is_active: 0,
        page: this.off_page,
        per_page: this.off_per_page,
        start_date: this.start_date2,
        end_date: this.end_date2,
        name: this.keyword2,
      };
      if (this.category_ids2.length > 0) {
        categoryIds = this.category_ids2.map((item) => item.id);
        params["category_ids"] = categoryIds;
      }
      if (this.media_ids2.length > 0) {
        mediaIds = this.media_ids2.map((item) => item.id);
        params["media_ids"] = mediaIds;
      }
      if (this.client_ids2.length > 0) {
        clientIds = this.client_ids2.map((item) => item.id);
        params["client_ids"] = clientIds;
      }
      if (this.survey_keywords2.length > 0) {
        params["survey_keywords"] = this.survey_keywords2;
      }
      this.axios
        .get(url, { params })
        .then((res) => {
          if (res.data.data.campaigns.length > 0) {
            res.data.data.campaigns.map((item) => {
              off_start = item.start_date
                ? item.start_date.substr(0, 10)
                : null;
              off_end = item.end_date ? item.end_date.substr(0, 10) : null;
              resCount = item.surveys.map((item) => item.response_count);
              resLimit = item.surveys.map((item) => item.response_limit);
              const result = resCount.reduce(function add(sum, currValue) {
                return sum + currValue;
              }, 0);
              const result2 = resLimit.reduce(function add(sum, currValue) {
                return sum + currValue;
              }, 0);
              off_items.push({
                id: item.id,
                name: item.name,
                date:
                  item.start_date || item.end_date
                    ? off_start + "~" + off_end
                    : null,
                start_date: item.start_date,
                end_date: item.end_date,
                surveys: item.surveys,
                count_survey: item.surveys_count,
                category_ids: item.categories.map((item) => item.id),
                category_name: item.categories.map((item) => item.name),
                media_ids: item.medias.map((item) => item.id),
                media_name: item.medias.map((item) => item.name),
                client_name: item.client.name,
                client_id: item.client_id,
                status: item.status,
                response_count: result,
                response_limit: result2,
              });
            });
          }
          this.close_items = off_items;
          this.offTotalCount = res.data.data.totalCount;
          this.off_loading = false;
          if (
            Number.isInteger(res.data.data.totalCount / this.off_per_page) === false
          ) {
            this.offPageCount =
              Math.floor(res.data.data.totalCount / this.off_per_page) + 1;
          } else {
            this.offPageCount = res.data.data.totalCount / this.off_per_page;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.open_items[this.editedIndex], this.editedItem);
        const data = {
          name: this.editedItem.name,
          category_ids: this.editedItem.category_ids.map((item) => item.id),
          media_ids: this.editedItem.media_ids.map((item) => item.id),
          client_id: this.editedItem.client_id,
          surveys: [this.editedItem.surveys],
          start_date: this.editedItem.start_date,
          end_date: this.editedItem.end_date,
          status: this.editedItem.status,
          fee_unit: Number(this.editedItem.fee_unit),
          fee: Number(this.editedItem.fee),
        };
        this.axios
          .put("api/v1/admin/campaigns/" + this.editedItem.id, data)
          .then(() => {
            this.getData();
            this.getHoldData();
            this.$store.commit("alert/showAlert", {
              message: `설문 토픽이 ${this.formTitle}되었습니다.`,
            });
          })
          .catch((err) => {
            this.$store.commit("alert/showAlert", {
              message: `${err.response.data.error.message}`,
            });
          });
      } else {
        if (this.is_reSave) {
          this.open_items.push(this.editedItem);
          const data = {
            name: this.editedItem.name,
            category_ids: this.editedItem.category_ids.map((item) => item.id),
            media_ids: this.editedItem.media_ids.map((item) => item.id),
            client_id: this.editedItem.client_id,
            surveys: this.editedItem.surveys,
            start_date: this.editedItem.start_date,
            end_date: this.editedItem.end_date,
            fee_unit: Number(this.editedItem.fee_unit),
            fee: Number(this.editedItem.fee),
            status: 1,
          };
          this.axios
            .post("api/v1/admin/campaigns", data)
            .then((res) => {
              this.getData();
              this.surveyEditedItem.campaign_id = res.data.data.id;
              this.campaignId = res.data.data.id;
              this.close();
              this.$store.commit("alert/showAlert", {
                message: `설문 토픽이 재${this.formTitle}되었습니다.`,
              });
            })
            .catch((err) => {
              if (err.response.data.error.statusCode === 409) {
                this.$store.commit("alert/showAlert", {
                  message: `${err.response.data.error.message} 설문 토픽 명을 변경해주세요.`,
                });
              } else {
                this.$store.commit("alert/showAlert", {
                  message: `${err.response.data.error.message}`,
                });
              }
            });
        } else {
          this.open_items.push(this.editedItem);
          const data = {
            name: this.editedItem.name,
            category_ids: this.editedItem.category_ids.map((item) => item.id),
            media_ids: this.editedItem.media_ids.map((item) => item.id),
            client_id: this.editedItem.client_id,
            surveys: this.editedItem.surveys,
            start_date: this.editedItem.start_date,
            end_date: this.editedItem.end_date,
            fee_unit: Number(this.editedItem.fee_unit),
            fee: Number(this.editedItem.fee),
            status: 1,
          };
          this.axios
            .post("api/v1/admin/campaigns", data)
            .then((res) => {
              this.getData();
              this.isActive = false;
              this.surveyEditedItem.campaign_id = res.data.data.id;
              this.campaignId = res.data.data.id;
              this.$store.commit("alert/showAlert", {
                message: `설문 토픽이 ${this.formTitle}되었습니다. 이제 문항 등록이 가능합니다.`,
              });
            })
            .catch((err) => {
              if (err.response.data.error.statusCode === 409) {
                this.$store.commit("alert/showAlert", {
                  message: `${err.response.data.error.message} 설문 토픽 명을 변경해주세요.`,
                });
              } else {
                this.$store.commit("alert/showAlert", {
                  message: `${err.response.data.error.message}`,
                });
              }
            });
        }
      }
      this.getData();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    surveyClose() {
      this.dialog2 = false;
      this.$nextTick(() => {
        this.surveyEditedItem = Object.assign({}, this.surveyDefaultItem);
        this.surveyEditedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    surveyCloseDelete() {
      this.surveyDialogDelete = false;
      this.$nextTick(() => {
        this.surveyEditedItem = Object.assign({}, this.surveyDefaultItem);
        this.surveyEditedIndex = -1;
      });
    },
    getCategory() {
      this.loading = true;
      let items = [];
      this.axios
        .get("api/v1/admin/categories", {})
        .then((res) => {
          if (res.data.data.categories.length > 0) {
            res.data.data.categories.map((item, index) => {
              items.push({
                no: index + 1,
                id: item.id,
                name: item.name,
              });
            });
          }
          this.category_items = items;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getMedia() {
      let medias = [];
      this.axios
        .get("api/v1/admin/medias?page=1&per_page=100", {})
        .then((res) => {
          if (res.data.data.medias.length > 0) {
            res.data.data.medias.map((item, index) => {
              medias.push({
                no: index + 1,
                id: item.id,
                name: item.name,
              });
            });
          }
          this.media_items = medias;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getSurvey(campaign_id) {
      this.survey_loading = true;
      let survey = [];
      this.axios
        .get(`api/v1/admin/surveys?campaign_id=${campaign_id}&per_page=100`, {})
        .then((res) => {
          if (res.data.data.surveys.length > 0) {
            res.data.data.surveys.map((item, index) => {
              let keywords = item.keywords ? item.keywords.split("#") : null;
              let not_keywords = item.not_keywords
                ? item.not_keywords.split("#")
                : null;
              survey.push({
                no: index + 1,
                id: item.id,
                campaign_id: item.campaign_id,
                keywords: keywords
                  ? keywords.filter((element) => element !== "")
                  : null,
                not_keywords: not_keywords
                  ? not_keywords.filter((element) => element !== "")
                  : null,
                questions: item.questions.map((item) => item.question),
                description: item.description,
                type: item.type,
                analytics_type: item.analytics_type,
                answer_cnt: item.answer_cnt,
                response_count: item.response_count,
                response_limit: item.response_limit,
                status: item.status,
                match_status: item.match_status,
              });
            });
            this.surveyLength = res.data.data.totalCount;
          }
          this.survey_items = survey;
          this.survey_loading = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    changeState(id, status) {
      if (status === "on") {
        if (confirm("해당 설문 토픽을 종료하시겠습니까?")) {
          const item = this.open_items.filter((item) => item.id === id)[0];
          const data = {
            name: item.name,
            media_ids: item.media_ids,
            category_ids: item.category_ids,
            client_id: item.client_id,
            start_date: item.start_date,
            end_date: item.end_date,
            status: item.status,
          };
          this.axios
            .put("api/v1/admin/campaigns/" + id, data)
            .then(() => {
              this.getData();
              this.getOffData();
              this.$store.commit("alert/showAlert", {
                message: `해당 설문 토픽이 종료되었습니다.`,
              });
            })
            .catch((err) => {
              this.$store.commit("alert/showAlert", {
                message: `${err.response.data.error.message}`,
              });
            });
        } else {
          this.getData();
        }
      } else if (status.startsWith('holdTo')) {
        const confirmMsg = status === 'holdToOn' ? "해당 설문 토픽을 활성화하시겠습니까?" : "해당 설문 토픽을 종료하시겠습니까?";
        if (confirm(confirmMsg)) {
          const item = this.hold_items.filter((item) => item.id === id)[0];
          const data = {
            name: item.name,
            category_ids: item.category_ids,
            client_id: item.client_id,
            start_date: item.start_date,
            end_date: item.end_date,
            status: status === 'holdToOn' ? 1 : 0,
          };
          this.axios
              .put("api/v1/admin/campaigns/" + id, data)
              .then(() => {
                this.getHoldData();
                this.getOffData();
                this.getData();
                this.$store.commit("alert/showAlert", {
                  message: `해당 설문 토픽이 활성화되었습니다.`,
                });
              })
              .catch((err) => {
                this.$store.commit("alert/showAlert", {
                  message: `${err.response.data.error.message}`,
                });
              });
        } else {
          this.getOffData();
        }
      } else if (status === "off") {
        if (confirm("해당 설문 토픽을 활성화하시겠습니까?")) {
          const item = this.close_items.filter((item) => item.id === id)[0];
          const data = {
            name: item.name,
            media_ids: item.media_ids,
            category_ids: item.category_ids,
            client_id: item.client_id,
            start_date: item.start_date,
            end_date: item.end_date,
            status: item.status,
          };
          this.axios
            .put("api/v1/admin/campaigns/" + id, data)
            .then(() => {
              this.getOffData();
              this.getData();
              this.$store.commit("alert/showAlert", {
                message: `해당 설문 토픽이 활성화되었습니다.`,
              });
            })
            .catch((err) => {
              this.$store.commit("alert/showAlert", {
                message: `${err.response.data.error.message}`,
              });
            });
        } else {
          this.getOffData();
        }
      }
    },
    surveyChangeState(id) {
      const item = this.survey_items.filter((item) => item.id === id)[0];
      let status = item.status && item.match_status === 1 ? "활성화" : "종료";
      if (confirm(`해당 항목을 ${status}하시겠습니까?`)) {
        let keyword = item.keywords ? "#" + item.keywords.join("#") : null;
        let dataArray = {};
        if (item.type === "긍부정 5지선다") {
          dataArray = [
            { question: item.questions[0] },
            { question: item.questions[1] },
            { question: item.questions[2] },
            { question: item.questions[3] },
            { question: item.questions[4] },
          ];
        } else if (item.type === "긍부정 4지선다") {
          dataArray = [
            { question: item.questions[0] },
            { question: item.questions[1] },
            { question: item.questions[2] },
            { question: item.questions[3] },
          ];
        } else if (item.type === "긍부정 3지선다") {
          dataArray = [
            { question: item.questions[0] },
            { question: item.questions[1] },
            { question: item.questions[2] },
          ];
        } else if (item.type === "긍부정 2지선다") {
          dataArray = [
            { question: item.questions[0] },
            { question: item.questions[1] },
          ];
        } else {
          dataArray = [{ question: item.questions[0] }];
        }
        const data = {
          campaign_id: item.campaign_id,
          description: item.description,
          type: item.type,
          keywords: keyword,
          analytics_type: item.analytics_type,
          response_limit: item.response_limit,
          status: item.status,
          match_status: item.match_status,
          questions: dataArray,
        };
        this.axios
          .put("api/v1/admin/surveys/" + id, JSON.stringify(data), {
            headers: {
              "Content-Type": "application/json",
            },
            dataType: "json",
          })
          .then(() => {
            this.getData();
            this.$store.commit("alert/showAlert", {
              message: `해당 항목이 ${status}되었습니다.`,
            });
          })
          .catch((err) => {
            this.$store.commit("alert/showAlert", {
              message: `${err.response.data.error.message}`,
            });
          });
      } else {
        this.getData();
        this.getSurvey(item.campaign_id);
      }
    },
    detail(item) {
      this.$router.push(`/campaigns/detail/` + item.id);
    },
    editItem(item) {
      let category_ids = this.category_items.filter((el) => {
        for (let i = 0; item.category_ids.length > i; i++) {
          if (item.category_ids[i] === el.id) {
            return el;
          }
        }
      });
      let media_ids = this.media_items.filter((el) => {
        for (let i = 0; item.media_ids.length > i; i++) {
          if (item.media_ids[i] === el.id) {
            return el;
          }
        }
      });

      this.editedIndex = this.open_items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.category_ids = category_ids;
      this.editedItem.media_ids = media_ids;
      this.dialog = true;
      this.isActive = false;
      this.getSurvey(item.id);
      this.surveyEditedItem.campaign_id = item.id;
    },
    editHoldItem(item) {
      let category_ids = this.category_items.filter((el) => {
        for (let i = 0; item.category_ids.length > i; i++) {
          if (item.category_ids[i] === el.id) {
            return el;
          }
        }
      });
      let media_ids = this.media_items.filter((el) => {
        for (let i = 0; item.media_ids.length > i; i++) {
          if (item.media_ids[i] === el.id) {
            return el;
          }
        }
      });

      this.editedIndex = this.hold_items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.category_ids = category_ids;
      this.editedItem.media_ids = media_ids;
      this.dialog = true;
      this.isActive = false;
      this.getSurvey(item.id);
      this.surveyEditedItem.campaign_id = item.id;
    },
    reSave(item) {
      let category_ids = this.category_items.filter((el) => {
        for (let i = 0; item.category_ids.length > i; i++) {
          if (item.category_ids[i] === el.id) {
            return el;
          }
        }
      });
      let media_ids = this.media_items.filter((el) => {
        for (let i = 0; item.media_ids.length > i; i++) {
          if (item.media_ids[i] === el.id) {
            return el;
          }
        }
      });
      this.is_reSave = true;
      this.isActive = true;
      this.editedIndex = this.open_items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.category_ids = category_ids;
      this.editedItem.media_ids = media_ids;
      this.dialog = true;
      this.getSurvey(item.id);
    },
    surveyEditItem(item) {
      this.surveyEditedIndex = this.survey_items.indexOf(item);
      this.surveyEditedItem = Object.assign({}, item);
      this.dialog2 = true;
      this.surveyEditedItem.campaign_id = item.id;
    },
    surveySave() {
      let keyword = this.surveyEditedItem.keywords
        ? "#" + this.surveyEditedItem.keywords.join("#")
        : null;
      let not_keyword = this.surveyEditedItem.not_keywords
        ? "#" + this.surveyEditedItem.not_keywords.join("#")
        : null;
      let dataArray = [];
      if (this.surveyEditedItem.type === "긍부정 5지선다") {
        dataArray = [
          { question: this.surveyEditedItem.questions[0] },
          { question: this.surveyEditedItem.questions[1] },
          { question: this.surveyEditedItem.questions[2] },
          { question: this.surveyEditedItem.questions[3] },
          { question: this.surveyEditedItem.questions[4] },
        ];
      } else if (this.surveyEditedItem.type === "긍부정 4지선다") {
        dataArray = [
          { question: this.surveyEditedItem.questions[0] },
          { question: this.surveyEditedItem.questions[1] },
          { question: this.surveyEditedItem.questions[2] },
          { question: this.surveyEditedItem.questions[3] },
        ];
      } else if (this.surveyEditedItem.type === "긍부정 3지선다") {
        dataArray = [
          { question: this.surveyEditedItem.questions[0] },
          { question: this.surveyEditedItem.questions[1] },
          { question: this.surveyEditedItem.questions[2] },
        ];
      } else if (this.surveyEditedItem.type === "긍부정 2지선다") {
        dataArray = [
          { question: this.surveyEditedItem.questions[0] },
          { question: this.surveyEditedItem.questions[1] },
        ];
      } else {
        dataArray = [{ question: this.surveyEditedItem.questions[0] }];
      }
      if (this.surveyEditedIndex > -1) {
        Object.assign(
          this.survey_items[this.surveyEditedIndex],
          this.surveyEditedItem
        );
        const data = {
          campaign_id: this.surveyEditedItem.campaign_id,
          description: this.surveyEditedItem.description,
          type: this.surveyEditedItem.type,
          keywords: keyword,
          not_keywords: not_keyword,
          analytics_type: this.surveyEditedItem.analytics_type,
          answer_cnt: parseInt(this.surveyEditedItem.answer_cnt),
          response_limit: this.surveyEditedItem.response_limit,
          status: this.surveyEditedItem.status,
          match_status: this.surveyEditedItem.match_status,
          questions: dataArray,
        };
        this.axios
          .put("api/v1/admin/surveys/" + this.surveyEditedItem.id, data)
          .then(() => {
            this.getData();
            this.$store.commit("alert/showAlert", {
              message: `문항이 수정되었습니다.`,
            });
          })
          .catch((err) => {
            this.$store.commit("alert/showAlert", {
              message: `${err.response.data.error.message}`,
            });
          });
      } else {
        this.survey_items.push(this.surveyEditedItem);
        const data = {
          campaign_id: this.surveyEditedItem.campaign_id ?? this.campaignId,
          description: this.surveyEditedItem.description,
          type: this.surveyEditedItem.type,
          keywords: keyword,
          not_keywords: not_keyword,
          analytics_type: this.surveyEditedItem.analytics_type,
          answer_cnt: parseInt(this.surveyEditedItem.answer_cnt),
          response_limit: this.surveyEditedItem.response_limit,
          status: 1,
          match_status: 0,
          questions: dataArray,
        };
        this.axios
          .post("api/v1/admin/surveys", data)
          .then((res) => {
            this.getData();
            this.getSurvey(res.data.data.campaign_id);
            this.$store.commit("alert/showAlert", {
              message: `문항이 등록되었습니다.`,
            });
          })
          .catch((err) => {
            this.$store.commit("alert/showAlert", {
              message: `${err.response.data.error.message}`,
            });
          });
      }

      this.surveyClose();
    },
    deleteItem(item) {
      this.editedIndex = this.open_items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$Swal
        .fire({
          text: "삭제 하시겠습니까?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          denyButtonText: `No`,
          confirmButtonColor: "#1D72DF",
          denyButtonColor: "#DC2B30",
          width: "400px",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.axios
              .delete("api/v1/admin/campaigns/" + this.editedItem.id)
              .then(() => {
                this.getData();
              })
              .catch((err) => {
                console.error(err);
              });
          } else if (result.isDenied) {
            this.closeDelete();
          }
        });
    },

    surveyDeleteItem(item) {
      this.surveyEditedIndex = this.survey_items.indexOf(item);
      this.sureditedItem = Object.assign({}, item);
      this.$Swal
        .fire({
          text: "삭제 하시겠습니까?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          denyButtonText: `No`,
          confirmButtonColor: "#1D72DF",
          denyButtonColor: "#DC2B30",
          width: "400px",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.axios
              .delete("api/v1/admin/surveys/" + this.sureditedItem.id)
              .then(() => {
                this.getSurvey();
              })
              .catch((err) => {
                console.error(err);
              });
          } else if (result.isDenied) {
            this.closeDelete();
          }
        });
    },
    typeChange() {
      this.surveyEditedItem.questions = [];
    },
    openSurveyModal(id) {
      this.surveyEditedIndex = -1;
      this.dialog2 = !this.dialog2;
      this.surveyRefresh();
      this.surveyEditedItem.campaign_id = id ?? this.campaignId;
    },
    openCampaignModal() {
      this.editedIndex = -1;
      this.isActive = true;
      this.survey_items = [];
      this.campaignRefresh();
      this.surveyRefresh();
    },
    campaignRefresh() {
      this.editedItem.name = null;
      this.editedItem.media_ids = [];
      this.editedItem.category_ids = [];
      this.editedItem.surveys = [];
      this.editedItem.start_date = null;
      this.editedItem.end_date = null;
    },
    surveyRefresh() {
      this.surveyEditedItem.campaign_id = null;
      this.surveyEditedItem.description = null;
      this.surveyEditedItem.type = null;
      this.surveyEditedItem.not_keywords = null;
      this.surveyEditedItem.analytics_type = null;
      this.surveyEditedItem.response_count = 0;
      this.surveyEditedItem.response_limit = 1000;
      this.surveyEditedItem.answer_cnt = 1;
      this.surveyEditedItem.status = null;
      this.surveyEditedItem.questions = [];
    },
  },
};
</script>
